import React, {useEffect, useRef, useState} from 'react';
import './App.css';

import {TabPanel} from "./common/TabPanel";
import {Box,  CssBaseline, Grid, Switch, Tab, Tabs} from "@mui/material";
// @ts-ignore
import kinoHintergrund from './audio/KinoHintergrund2.mp3';
// @ts-ignore
import psycho from './audio/Psycho.mp3';
// @ts-ignore
import flasch from './audio/Tim Fischer - Flasch instrumental.mp3';
// @ts-ignore
import jingle3 from './audio/Jingle3.mp3';
// @ts-ignore
import happyBirthday20 from './audio/HappyBirthday.mp3';
// @ts-ignore
import NiemalsGehtManSoGanz from './audio/Trude Herr - Niemals geht man so ganz.mp3';

import {AudioPlayerHowler} from "./common/audio-player-howler";

const jingleVolume = 0.8
function App() {
    const hintergrundRef = useRef<any>()
    const [tabValue, setTabValue] = useState(0)
    const [showControl, setShowControl] = useState<boolean>(false)
    let tabIndex = 0

    const onOutroPlay = ()=> {
        if (hintergrundRef.current) {
            hintergrundRef.current.stop()
            hintergrundRef.current.seek(0)
        }
    }

    return (
        <div className="App">
            <CssBaseline/>
            <Box className="sidebar" gridArea="sidebar">
                <Tabs orientation="vertical"
                      variant="scrollable"
                      value={tabValue}
                      onChange={(e, v) => setTabValue(v)}>
                    <Tab label="Flasch"/>
                    <Tab label="Kino"/>
                    <Tab label="Helga (Backup)"/>
                </Tabs>
            </Box>

            <Box className="main" gridArea="main" overflow="scroll">
                <TabPanel value={tabValue} index={tabIndex++}>
                    <Grid container direction="column">
                        <AudioPlayerHowler audioSrc={flasch} buttonLabel="Die Flasch" text="Song von Chris"/>
                    </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={tabIndex++}>
                    <Grid container direction="column">

                        <AudioPlayerHowler volume={jingleVolume} audioSrc={happyBirthday20} buttonLabel="Intro Kino" text="Während Vorhang aufgeht... START" />
                        <AudioPlayerHowler internalRef={hintergrundRef} volume={0.7} audioSrc={kinoHintergrund} buttonLabel="Film Hintergrund Start" text="Los, wenn alle sitzen" />
                        <AudioPlayerHowler volume={0.8} audioSrc={psycho} buttonLabel="Psycho" text="Nachdem alle das Knie gestreichelt haben und wieder Ruhe eingekehrt ist." />
                        <AudioPlayerHowler onPlay={onOutroPlay} volume={jingleVolume} audioSrc={jingle3} buttonLabel="Outro Kino" text="Matijas und Finja kuscheln auf den Sitzen... START" />
                    </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={tabIndex++}>
                    <Grid container direction="column">
                        <AudioPlayerHowler audioSrc={NiemalsGehtManSoGanz} buttonLabel="Trude Herr - Niemals geht man so ganz (Instrumental)"/>
                    </Grid>
                </TabPanel>

            </Box>
        </div>
    );
}

export default App;
