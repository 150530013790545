// @flow
import * as React from 'react';
import {Box, Button, Grid} from "@mui/material";
import {useRef, useState} from "react";
// @ts-ignore
import ReactHowler from 'react-howler'

type Props = {
    audioSrc: any
    buttonLabel: string
    text?: string
    fadeDownSpeed?: number
    volume?: number
    controls?: boolean
    loop?: boolean
    internalRef?: React.MutableRefObject<any>
    onPlay?: () => void
};

export const AudioPlayerHowler = (props: Props) => {
    const internalAudioPlayerRef = useRef<any>(null)
    const audioPlayerRef = props.internalRef ?? internalAudioPlayerRef
    const [played, setPlayed] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const volumeRef = useRef<number>(1)


    const onPlayClick = () => {
        if (playing && props.fadeDownSpeed) {
            fadeDown(audioPlayerRef, props.volume || 1)
        } else {
            setPlaying(p => !p)
            setPlayed(true)
            audioPlayerRef.current.seek(0)
        }
    }

    const onLoad = () => {
        setLoaded(true)
        audioPlayerRef.current.volume(props.volume)
    }
    const onStop = () => {


        setPlaying(false);

    }

    const step = 0.05
    const fadeDown = (ref: any, initVolume: number = 1) => {
        if (props.fadeDownSpeed && volumeRef.current > step) {
            ref.current.volume(volumeRef.current - step)
            volumeRef.current = volumeRef.current - step
            setTimeout(() => fadeDown(ref, initVolume), props.fadeDownSpeed)
        } else {
            ref.current.stop()
            ref.current.seek(0)
            ref.current.volume(initVolume)
            volumeRef.current = initVolume
        }
    }


    const onEnd = () => {
        if (props.loop) {
            audioPlayerRef.current.seek(0)
            audioPlayerRef.current.play();

        } else {
            setPlaying(false);
        }

    }
    return (
        <Grid item>
            <Box margin={1}>
                <Box marginTop={2}>{props.text}</Box>
                <Button disabled={!loaded} className={"audio-button"} variant={playing ? "contained" : "outlined"} color={played ? 'secondary' : 'primary'}
                        onClick={() => onPlayClick()}>{props.buttonLabel}</Button>
                <ReactHowler onLoad={onLoad} src={props.audioSrc} playing={playing} ref={audioPlayerRef} preload={true} onStop={onStop} onEnd={onEnd} onPlay={props.onPlay}/>
                <hr />
            </Box>
        </Grid>
    );
};